<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t("edit") }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('name')" v-model="editTeacher.name" outlined dense color="#757575"
              type="text"></v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode" :label="$t('username')" v-model="editTeacher.username" outlined dense color="#757575"
              type="text"></v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field dense :dark="$store.state.isDarkMode" outlined :label="$t('birthday date')" type="date" 
                              v-model="editTeacher.birth_date" clearable>
            </v-text-field>
            </v-col>
          <v-col md="6">
            <v-text-field dense :dark="$store.state.isDarkMode" outlined :label="$t('Date of joining')" type="date" 
                              v-model="editTeacher.joining_date" clearable>
            </v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field :dark="$store.state.isDarkMode"  :label="$t('specialization')" v-model="editTeacher.specialization" outlined dense
              color="#757575" type="text"></v-text-field></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" depressed @click="editRecord" :loading="editBtnLoading">{{ $t("edit")
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      editTeacher: {
        name: "",
        username: "",
        birth_date: "",
        joining_date: "",
        specialization: "",
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
    editDialog() {
      this.editTeacher.name = this.recordData.name;
      this.editTeacher.birth_date = this.recordData.birth_date;
      this.editTeacher.joining_date = this.recordData.joining_date;
      this.editTeacher.specialization = this.recordData.specialization;
      this.editTeacher.username = this.recordData.username;
      // this.editTeacher.password = this.recordData.password;
    },
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(`/teacher/${this.recordData.id}`, {
          name: this.editTeacher.name,
          username: this.editTeacher.username,
          birth_date: this.editTeacher.birth_date,
          joining_date: this.editTeacher.joining_date,
          specialization: this.editTeacher.specialization,
        });
        this.$Notifications(
          this.$t("edit success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
        console.log("re", err);
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  computed: {},
  created() {
    this.editTeacher.name = this.recordData.name;
    this.editTeacher.birth_date = this.recordData.birth_date;
    this.editTeacher.joining_date = this.recordData.joining_date;
    this.editTeacher.specialization = this.recordData.specialization;
    this.editTeacher.username = this.recordData.username;
    // this.editTeacher.password = this.recordData.password;
  },
};
</script>

<style></style>
