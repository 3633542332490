<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-container>
        <v-row>
          <v-col md="2" class="mr-2" v-if="returnAbility('teachers:store')">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
                  <v-icon size="20">mdi-plus</v-icon> {{ $t("add teacher") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="d-flex justify-center card-title__heading">
                  {{ $t("add teacher") }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="6">
                      <v-text-field :dark="$store.state.isDarkMode" :label="$t('name')" v-model="newTeacher.name" outlined dense color="#757575"
                        type="text"></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <v-text-field :dark="$store.state.isDarkMode" :label="$t('username')" v-model="newTeacher.username" outlined dense color="#757575"
                        type="text"></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <v-text-field dense :dark="$store.state.isDarkMode" outlined :label="$t('birthday date')" type="date" 
                                        v-model="newTeacher.birth_date" clearable>
                      </v-text-field>
                      </v-col>
                    <v-col md="6">
                      <v-text-field dense :dark="$store.state.isDarkMode" outlined :label="$t('Date of joining')" type="date" 
                                        v-model="newTeacher.joining_date" clearable>
                      </v-text-field>
                    </v-col>
                    <v-col md="6">
                      <v-text-field :dark="$store.state.isDarkMode" :label="$t('password')" v-model="newTeacher.password" outlined dense color="#757575"
                        type="text"></v-text-field>
                    </v-col>
                   
                    <v-col md="6">
                      <v-text-field :dark="$store.state.isDarkMode"  :label="$t('specialization')" v-model="newTeacher.specialization" outlined dense
                        color="#757575" type="text"></v-text-field></v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn color="green" class="white--text" @click="submitNewTeacher" :loading="submitBtnLoading"
                    :disabled="disableSubmitBtn">{{ $t("add") }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col md="2">
            <v-btn color="blue" dark block @click="exportToExcel(filters)">{{ $t('hr.time.export') }}
            </v-btn>
          </v-col>
          <v-col md="2">
            <v-text-field :dark="$store.state.isDarkMode" v-model="name" :label="$t('search by name')" dense outlined hide-details
              clearable></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field :dark="$store.state.isDarkMode" v-model="username" :label="$t('search by user name')" dense outlined hide-details
              clearable></v-text-field>
          </v-col>
          <v-col md="2.5" class="d-flex justify-center align-center">
            <v-text-field :dark="$store.state.isDarkMode" v-model="specialization" :label="$t('search by phone specialization')" dense outlined
              hide-details clearable></v-text-field>
            <v-icon :dark="$store.state.isDarkMode"  class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData" serverRoute="/teacher"
        @re-fetch-paginated-data="fetchData($event)" :enableDelete="displayDeleteIcon" :flaggedItems="flaggedItemsData">
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon color="green" @click="$router.push(`teacher/${row.id}`)">mdi-eye</v-icon>
          <app-edit-teacher-modal @recordUpdated="fetchData" :recordData="row"></app-edit-teacher-modal>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import editTeacherModal from "./editTeacherModal.vue";
import axios from "axios";

export default {
  components: {
    appEditTeacherModal: editTeacherModal,
  },
  data() {
    return {
      name: "",
      specialization: null,
      username: "",
      joining_date_from: null,
      dialog: false,
      birthdayDialog: false,
      joining_date_Dialog: false,
      joining_date_from_Dialog: false,
      submitBtnLoading: false,
      loading: false,
      flaggedItemsData: [
        "created_at",
        "email_verified_at",
        "image",
        "token",
        "type",
        "updated_at",
        "name",
        // "joining_date",
      ],
      newTeacher: {
        name: "",
        username: "",
        password: "",
        birth_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        joining_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        specialization: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "teachers/getTableOptions",
    }),
    disableSubmitBtn() {
      if (
        !this.newTeacher.name ||
        !this.newTeacher.birth_date ||
        !this.newTeacher.joining_date ||
        !this.newTeacher.specialization ||
        !this.newTeacher.username ||
        !this.newTeacher.password
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchTeachers: "teachers/fetchTeachers",
    }),

    fetchData(page) {
      this.dialog = false;
      this.fetchTeachers({
        params: {
          page,
          name: this.name,
          specialization: this.specialization,
          username: this.username,
          joining_date_from: this.joining_date_from,
        },
      });
    },
    async exportToExcel() {

      try {

        const excel = await axios.get(`/teacher/export`, {
          params: {
            name: this.name,
            specialization: this.specialization,
            username: this.username,
          },
          responseType: 'blob'
        });
        saveAs(excel.data)


      } catch (err) {
        console.log('err', err);
      } finally {

      }

    },
    async submitNewTeacher() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/teacher", this.newTeacher);
        this.$Notifications(
          this.$t("add success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData();
        this.dialog = false;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  created() {
    if (this.returnAbility("teacher:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style></style>
